const colors = require('tailwindcss/colors');

const systemFontStack = [
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Helvetica',
  'Arial',
  'sans-serif',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
];

module.exports = {
  theme: {
    colors: {
      transparent: 'transparent',

      white: '#FFFFFF',
      black: '#292829',

      gray: colors.gray,

      pink: {
        100: '#FAC8E5',
        300: '#FF73C5',
        500: '#F00B8F',
        700: '#B0096A',
        900: '#700644',
      },

      red: '#D15C5C',
      green: '#81D15C',

      facebook: '#1877f2',
      twitter: '#1da1f2',
      linkedin: '#007bb5',
      instagram: '#c32aa3',
      spotify: '#1ed760',
    },

    fontFamily: {
      display: ['BebasNeuePro-Bold', 'Arial Narrow', ...systemFontStack],
      sans: ['AvenirNextLTPro', ...systemFontStack],
    },
  },
};
